import React, { useState, useEffect } from 'react';
import styles from './Gallery.module.css'; // Assuming you have CSS modules for styles
import gallerydata from './Gallerydata';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Helmet } from 'react-helmet-async';
const categories = [
  'All',
  'School Campus',
  'Facilities',
  'Celebrations',
  'Sports & Competitions',
  'Activities',
  'Social Connection',
  'Summer Camp',
  'Trips Excursion',
  'Weather Station',
  'Republic Day',
];

const Gallery = () => {
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [lightboxIndex, setLightboxIndex] = useState(0);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false); // State for tracking image loading

  const filteredGallery = selectedCategory === 'All'
    ? gallerydata
    : gallerydata.filter(item => item.category === selectedCategory);

  useEffect(() => {
    const loadImage = () => {
      const images = document.querySelectorAll('.gallery_item img');
      let loadedCount = 0;

      images.forEach(image => {
        if (image.complete) {
          loadedCount++;
        } else {
          image.addEventListener('load', () => {
            loadedCount++;
            if (loadedCount === images.length) {
              setImagesLoaded(true);
            }
          });
        }
      });

      if (loadedCount === images.length) {
        setImagesLoaded(true);
      }
    };

    loadImage();
  }, [filteredGallery]); 

  const openLightbox = (index) => {
    setLightboxIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  return (
    <>
      {!imagesLoaded && (
        <div className={styles.loader}>
          <div className={styles.loader_inner}></div>
        </div>
      )}

      <div className={styles.gallery}>
        <div className={styles.gallery_head}>
          <div className={styles.overlay}></div>
          <div className={styles.gallery_head_text}>
            <h1>Gallery</h1>
          </div>
        </div>

        <div className={styles.category_selector}>
          {categories.map(category => (
            <button
              key={category}
              className={selectedCategory === category ? styles.active : ''}
              onClick={() => setSelectedCategory(category)}
            >
              {category}
            </button>
          ))}
        </div>

        <div className={styles.gallery_main}>
          {filteredGallery.map((item, index) => (
            <div key={index} className={styles.gallery_item} onClick={() => openLightbox(index)}>
              <img src={item.image} alt={`Gallery ${index + 1}`} />
            </div>
          ))}
        </div>
      </div>

      {lightboxOpen && (
        <Lightbox
          mainSrc={filteredGallery[lightboxIndex].image}
          nextSrc={filteredGallery[(lightboxIndex + 1) % filteredGallery.length].image}
          prevSrc={filteredGallery[(lightboxIndex + filteredGallery.length - 1) % filteredGallery.length].image}
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() =>
            setLightboxIndex((lightboxIndex + filteredGallery.length - 1) % filteredGallery.length)
          }
          onMoveNextRequest={() =>
            setLightboxIndex((lightboxIndex + 1) % filteredGallery.length)
          }
        />
      )}
    </>
  );
};

export default Gallery;
