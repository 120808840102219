import React from 'react';
import Slider from './Slider/SliderHero';
import Absolute from './Absolute/Absolute';
import Intro from './Intro/Intro';
import Faculty from './Faculty/Faculty';
import Testimonials from './Testimonials/Testimonials';
import Why from './Why/Why';
import Principal from './Principal/Principal';
import styles from './Home.module.css';
import { Helmet } from 'react-helmet-async';
const Home = () => {
  return (
    <>
    {/* SEO */}
    <Helmet>
        <title>Best CBSE, SSC School & Jr College in Undri, Pune</title>
        <meta name="description" content="Discover Hillgreen Best CBSE School, SSC School & Junior College Undri,  Pune. Empowering students through holistic education." />
        <meta name="keywords" content="Hillgreen High School, admission open, school in Pune, quality education, best school faculty" />
        <meta property="og:title" content="Hillgreen High School - Admission Open!" />
        <meta property="og:description" content="Enroll now at Hillgreen High School for a better future. Our experienced faculty and world-class facilities await you." />
        <meta property="og:image" content="URL_TO_IMAGE_HERE" />
        <meta property="og:url" content="https://www.hillgreenhighschool.in/" />
        <meta name="twitter:title" content="Hillgreen High School - Admission Open!" />
        <meta name="twitter:description" content="Join Hillgreen High School today! Enrollment is open for a future of excellence." />
        <meta name="twitter:image" content="URL_TO_IMAGE_HERE" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>


      <div className={styles.marquee}>
        <p>
          Admission Open! Enroll now and secure your child's future.{' '}
          Connect with Us +91 73919 17181 / <a href="mailto:hghs_pune@yahoo.co.in" aria-label="Email">hghs_pune@yahoo.co.in</a>
        </p>
      </div>
      <Slider />
      <Absolute />
      <Intro />
      <Faculty />
      <Principal />
      <Testimonials />
      <Why />
    </>
  );
};

export default Home;
