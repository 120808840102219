import React from 'react'
import styles from './Academics.module.css'
import { Helmet } from 'react-helmet-async'


const Academics = () => {
  return (
    <>
    <Helmet>
        <title>Enhancing Growth in Academic Through Projects & Teamwork</title>
        <meta name="description" content="Discover how teamwork and projects can unlock children's potential in academics. We focus on recognizing individual talents to foster growth" />

    </Helmet>
        <div className={styles.academics}>
            <div className={styles.academics_head}>
                <div className={styles.overlay}></div>
                <div className={styles.academics_head_text}>
                    <h1>Academics</h1>
                </div>
            </div>
            <div className={styles.academics_main}>
                <div className={styles.academics_main_content}>
                    <h2>Academics</h2>
                    <p>Children have unlimited potential and infinite ability within them to achieve a lot. What is important, is that we recognise each child’s individual inclinations and aptitude at an early stage. Only then can they be exposed to the right stimuli in order to develop their specific talents.</p>
                    <br/>
                    <p>While learning or undertaking projects, inside the classroom and outside, students are motivated to work together in small groups. This not only helps them appreciate the importance of teamwork, but also provides the training ground on which to exercise their leadership skills.</p>
                </div>
            </div>
        </div>
    </>
  )
}

export default Academics