import React from 'react'
import styles from './Documents.module.css'
import { Helmet } from 'react-helmet-async';

const Documents = () => {
    return (
        <>
            <Helmet>
                <title>Documents Required for Admission | Hillgreen High School</title>
                <meta name="description" content="List of essential documents required for the admission process at Hillgreen High School. Make sure to gather the necessary documents such as Transfer Certificate, Birth Certificate, and other important papers." />
                <meta name="keywords" content="documents required for admission, school admission documents, Hillgreen High School, Transfer Certificate, Birth Certificate, Admission Process" />
                {/* Open Graph Meta Tags */}
                <meta property="og:title" content="Documents Required for Admission | Hillgreen High School" />
                <meta property="og:description" content="Visit Hillgreen High School for the list of documents required for admission. Prepare all necessary documents including Transfer Certificate and Birth Certificate for a smooth admission process." />
                <meta property="og:image" content="URL_TO_IMAGE_HERE" />
                <meta property="og:url" content="https://www.hillgreenhighschool.in/documents" />
                <meta property="og:type" content="website" />

                {/* Twitter Card Meta Tags */}
                <meta name="twitter:title" content="Documents Required for Admission | Hillgreen High School" />
                <meta name="twitter:description" content="Ensure all documents are ready for your child's admission at Hillgreen High School. Check the complete list of documents required, including Transfer Certificate and more." />
                <meta name="twitter:image" content="URL_TO_IMAGE_HERE" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="robots" content="index, follow" />
            </Helmet>

            <div className={styles.documents}>
                <div className={styles.documents_head}>
                    <div className={styles.overlay}></div>
                    <div className={styles.documents_head_text}>
                        <h1>Documents</h1>
                    </div>
                </div>

                <div className={styles.documents_main}>
                    <div className={styles.documents_list}>
                        <h1>Documents Required</h1>


                        <ul className={styles.documents_list_li}>
                            <div className={styles.docs_list_flex}>
                                <div>
                                    <div className={styles.loader}></div>
                                </div>
                                <li>TRANSFER CERTIFICATE</li>
                            </div>
                            <div className={styles.docs_list_flex}>
                                <div>
                                    <div className={styles.loader}></div>
                                </div>
                                <li>BIRTH CERTIFICATE</li>
                            </div>
                            <div className={styles.docs_list_flex}>
                                <div>
                                    <div className={styles.loader}></div>
                                </div>
                                <li>TWO RECENT COLOR PHOTOS OF STUDENT</li>
                            </div>
                            <div className={styles.docs_list_flex}>
                                <div>
                                    <div className={styles.loader}></div>
                                </div>
                                <li>TWO RECENT COLOR PHOTOS OF STUDENT</li>
                            </div>
                            <div className={styles.docs_list_flex}>
                                <div>
                                    <div className={styles.loader}></div>
                                </div>
                                <li>RESULT OF LAST YEAR CLASS ATTENDED</li>
                            </div>
                            <div className={styles.docs_list_flex}>
                                <div>
                                    <div className={styles.loader}></div>
                                </div>
                                <li>ADHAR CARD OF STUDENT</li>
                            </div>

                        </ul>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Documents