import React, { useState } from 'react';
import styles from './Footer.module.css';
import logo from '../../Assets/Navbar/Logo.png';
import { Link } from 'react-router-dom';

const Footer = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        message: "",
    });


    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await fetch("https://yeskondomedia.com/Mail/Hillgreencontact.php", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: new URLSearchParams(formData).toString(),
        });

        const result = await response.text();
        alert(result);
    };



    return (
        <>
            <div className={styles.footer}>
                <div className={styles.footer_main_flex}>
                    <div className={styles.footer_box}>
                        <div className={styles.footer_about}>
                            <div className={styles.logo_container}>
                                <img src={logo} alt='Hillgreen Logo' className={styles.logo} />
                                <p>Hillgreen High School <br /> & Jr. College</p>
                            </div>
                            <p>Hillgreen High School is best <span className={styles.bold}> CBSE School, SSC School </span> & Jr college in Undri, Pune is an unaided linguistic minority institution is a prestigious project of Kerala People's Education Society</p>
                            <div className={styles.social_icons}>
                                <a target='blank' href="https://www.facebook.com/profile.php?id=61555247352613"><i className="fab fa-facebook"></i></a>
                                <a target='blank' href="https://www.instagram.com/hillgreen_high_school/"><i className="fab fa-instagram"></i></a>
                                <a target='blank' href="https://www.youtube.com/@hhsjcpune5903"><i className="fab fa-youtube"></i></a>
                            </div>
                        </div>
                    </div>

                    <div className={styles.footer_box}>
                        <h2>Admission</h2>
                        <ul className={styles.footer_links}>
                            <li><Link to='/onlineadmission' className={styles.footer_links_main}>Online Admission</Link></li>
                            <li><Link to='/offlineadmission' className={styles.footer_links_main}>Offline Admission</Link></li>
                            <li><Link to='/documents' className={styles.footer_links_main}>Documents</Link></li>
                            <li><Link to='/bankers' className={styles.footer_links_main}>Our Bankers</Link></li>
                        </ul>
                    </div>

                    <div className={styles.footer_box}>
                        <h2>Quick Links</h2>
                        <ul className={styles.footer_links}>
                            <li><Link to='/' className={styles.footer_links_main}>Home</Link></li>
                            <li><Link to='/schoolhistory' className={styles.footer_links_main}>School History</Link></li>
                            <li><Link to='/teachers' className={styles.footer_links_main}>Teachers</Link></li>
                            <li><Link to='/campus' className={styles.footer_links_main}>Campus</Link></li>
                        </ul>
                    </div>

                    <div className={styles.footer_box}>
                        <form className={styles.footer_form} onSubmit={handleSubmit}>
                            <h2>Contact Us</h2>
                            <input type="text" placeholder="Your Name" />
                            <input type="email" placeholder="Your Email" />
                            <input type="tel" placeholder="Your Phone" />
                            <textarea placeholder="Your Message"></textarea>
                            <button type="submit">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
            <div className={styles.footer_bottom}>
                <p>Copyright © 2022 Hillgreen High School & Jr College | All Rights Reserved | <a href='https://www.yeskondomedia.com' target='_blank'>Made With ❤️ By Yeskondo Media PVT. LTD.</a></p>
            </div>
        </>
    );
}

export default Footer;
