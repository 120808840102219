import React, { useState } from 'react'
import styles from './Bankers.module.css'
import { Helmet } from 'react-helmet-async';

const Bankers = () => {
    const [formData, setFormData] = useState({
        academicYear: '',
        studentClass: '',
        firstName: '',
        lastName: '',
        fatherName: '',
        gender: '',
        motherName: '',
        whatsappNumber: '',
        email: '',
        phoneNumber: '',
        address: '',
        street: '',
        area: '',
        city: '',
        state: '',
        pinCode: '',
        transactionNumber: '',
        screenshot: null
    });


    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData({
            ...formData,
            [name]: files ? files[0] : value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const data = new FormData();
        Object.keys(formData).forEach((key) => {
            data.append(key, formData[key]);
        });

        fetch('https://hillgreenhighschool.in/form-handler.php', {
            method: 'POST',
            body: data,
        })
            .then(response => response.text())
            .then(result => {
                alert('Form submitted successfully');
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };


    return (
        <>
            <Helmet>
                <title>Payment Details & Bank Account Information | Hillgreen High School</title>
                <meta name="description" content="View the payment details and school bank account information for fee payments at Hillgreen High School. Complete the payment confirmation form to confirm your payment." />
                <meta name="keywords" content="Hillgreen High School, payment details, bank account details, fee payment confirmation, school fee payment" />

                {/* Open Graph Meta Tags */}
                <meta property="og:title" content="Payment Details & Bank Account Information | Hillgreen High School" />
                <meta property="og:description" content="View the payment details and bank account information to complete your fee payments. Submit the payment confirmation form for successful payment verification." />
                <meta property="og:image" content="URL_TO_IMAGE_HERE" />
                <meta property="og:url" content="https://www.hillgreenhighschool.in/bankers" />
                <meta property="og:type" content="website" />

                {/* Twitter Card Meta Tags */}
                <meta name="twitter:title" content="Payment Details & Bank Account Information | Hillgreen High School" />
                <meta name="twitter:description" content="Find payment details, school bank account info, and the payment confirmation form to verify your fee payment at Hillgreen High School." />
                <meta name="twitter:image" content="URL_TO_IMAGE_HERE" />
                <meta name="twitter:card" content="summary_large_image" />
                {/* Robots Meta Tag */}
                <meta name="robots" content="index, follow" />
            </Helmet>


            <div className={styles.bankers}>
                <div className={styles.bankers_head}>
                    <div className={styles.overlay}></div>
                    <div className={styles.bankers_head_text}>
                        <h1>Payment Details</h1>
                    </div>
                </div>

                <div className={styles.form_flex}>
                    <div className={styles.banker_main}>
                        <div className={styles.banker_main_head}>
                            <h1>School Bank Account Details</h1>
                        </div>
                        <div className={styles.banker_main_flex}>
                            <div className={styles.banker_main_box}>
                                <i class="fa-solid fa-building-columns"></i>
                                <div className={styles.bankdetails}>
                                    <li> <span className={styles.font_color}>A/C Name:</span> Hillgreen High School and Junior College</li>
                                    <li> <span className={styles.font_color}>Bank Name:</span> Union Bank of India</li>
                                    <li> <span className={styles.font_color}>A/C. Number:</span> 6169 0201 0000 392</li>
                                    <li> <span className={styles.font_color}>IFSC Code:</span> UBIN0561690</li>
                                    <li> <span className={styles.font_color}>Branch:</span> Undri</li>
                                </div>
                            </div>


                            <div className={styles.banker_main_box}>
                                <i class="fa-solid fa-building-columns"></i>
                                <div className={styles.bankdetails}>
                                    <li> <span className={styles.font_color}>A/C Name:</span> Hillgreen High School and Junior College</li>
                                    <li> <span className={styles.font_color}>Bank Name:</span> Federal Bank Limited</li>
                                    <li> <span className={styles.font_color}>A/C. Number:</span> 18190100019677</li>
                                    <li> <span className={styles.font_color}>IFSC Code:</span>FDRL0001819</li>
                                    <li> <span className={styles.font_color}>Branch:</span> Bibwewadi</li>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.payment_confirmation_form}>
                        <div className={styles.payment_confirmation_head}>
                            <h1>Payment Confirmation Form</h1>
                        </div>


                        <div className={styles.main_form}>
                            <div className={styles.formContainer}>

                                <form onSubmit={handleSubmit} className={styles.form}>
                                    <div className={styles.formGroup}>
                                        <label>Academic Year</label>
                                        <select name="academicYear" value={formData.academicYear} onChange={handleChange} required>
                                            <option value="">Select Year</option>
                                            <option value="2022-2023">2022-2023</option>
                                            <option value="2023-2024">2023-2024</option>
                                        </select>
                                    </div>

                                    <div className={styles.formGroup}>
                                        <label>Select Class</label>
                                        <select name="studentClass" value={formData.studentClass} onChange={handleChange} required>
                                            <option value="">Select Class</option>
                                            <option value="LKG">LKG</option>
                                            <option value="UKG">UKG</option>
                                            <option value="Nursery">Nursery</option>
                                            {Array.from({ length: 9 }, (_, i) => (
                                                <option key={i + 1} value={`Class ${i + 1}`}>{`Class ${i + 1}`}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className={styles.formGroup}>
                                        <label>Student First Name</label>
                                        <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} required />
                                    </div>

                                    <div className={styles.formGroup}>
                                        <label>Student Last Name</label>
                                        <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} required />
                                    </div>

                                    <div className={styles.formGroup}>
                                        <label>Father Name</label>
                                        <input type="text" name="fatherName" value={formData.fatherName} onChange={handleChange} required />
                                    </div>

                                    <div className={styles.formGroup}>
                                        <label>Select Gender</label>
                                        <select name="gender" value={formData.gender} onChange={handleChange} required>
                                            <option value="">Select Gender</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                        </select>
                                    </div>

                                    <div className={styles.formGroup}>
                                        <label>Mother Name</label>
                                        <input type="text" name="motherName" value={formData.motherName} onChange={handleChange} required />
                                    </div>

                                    <div className={styles.formGroup}>
                                        <label>WhatsApp Number</label>
                                        <input type="text" name="whatsappNumber" value={formData.whatsappNumber} onChange={handleChange} required />
                                    </div>

                                    <div className={styles.formGroup}>
                                        <label>Email</label>
                                        <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                                    </div>

                                    <div className={styles.formGroup}>
                                        <label>Phone Number</label>
                                        <input type="text" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} required />
                                    </div>

                                    <div className={styles.formGroup}>
                                        <label>Flat/Building/Apartment</label>
                                        <input type="text" name="address" value={formData.address} onChange={handleChange} required />
                                    </div>

                                    <div className={styles.formGroup}>
                                        <label>Street/Road/Lane Number</label>
                                        <input type="text" name="street" value={formData.street} onChange={handleChange} required />
                                    </div>

                                    <div className={styles.formGroup}>
                                        <label>Area/Locality</label>
                                        <input type="text" name="area" value={formData.area} onChange={handleChange} required />
                                    </div>

                                    <div className={styles.formGroup}>
                                        <label>City</label>
                                        <input type="text" name="city" value={formData.city} onChange={handleChange} required />
                                    </div>

                                    <div className={styles.formGroup}>
                                        <label>State</label>
                                        <input type="text" name="state" value={formData.state} onChange={handleChange} required />
                                    </div>

                                    <div className={styles.formGroup}>
                                        <label>Pin Code</label>
                                        <input type="text" name="pinCode" value={formData.pinCode} onChange={handleChange} required />
                                    </div>

                                    <div className={styles.formGroup}>
                                        <label>Online Transaction Number</label>
                                        <input type="text" name="transactionNumber" value={formData.transactionNumber} onChange={handleChange} required />
                                    </div>

                                    <div className={styles.formGroup}>
                                        <label>Upload Transaction Screenshot</label>
                                        <input type="file" name="screenshot" onChange={handleChange} required />
                                    </div>

                                    <button type="submit" className={styles.submitButton}>Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Bankers