import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import styles from './Blogs.module.css';
import preschool from '../../../Assets/Gallery/1.webp';
import preschool2 from '../../../Assets/Gallery/2.webp';
import preschool3 from '../../../Assets/Gallery/24.webp';
import bg3 from '../../../Assets/bg3.webp';

const blogs = [
  {
    id: 1,
    title: "Best Preschool in Pune: 5 Essential Tips for Parents | Hillgreen High School",
    description: "Looking for the best preschool in Pune? Discover 5 key factors every parent should consider, including safety, facilities, qualified teachers, and effective communication.",
    url: "best-preschool-in-pune-5-tips-for-parents",
    imageUrl: preschool,
  },
  {
    id: 2,
    title: "10 Easy Preschool Activities to Encourage Creativity and Learning",
    description: "Discover 10 simple and fun preschool activities using everyday items to foster creativity, learning, and motor skills development in young children. Perfect for at-home education!",
    url: "10-easy-preschool-activities",
    imageUrl: preschool2,
  },
  {
    id: 3,
    title: "10 Key Tips for CBSE Students Facing 10th Board Exams from Hillgreen High School",
    description: "Prepare for your 10th Board Exams with confidence! Explore 10 expert tips from Hillgreen High School Undri, Pune, designed to help CBSE students excel.",
    url: "10-Tips-for-CBSE-Students-Facing-10th-Board-Exams",
    imageUrl: preschool3,
  },
  {
    id: 4,
    title: "Hillgreen High School – The Best Preschool in Undri, Pune",
    description: "Are you searching for the best preschool in Undri, Pune to give your child a strong foundation for learning? Look no further than Hillgreen High School, where we provide an enriching environment designed for holistic development.",
    url: "hillgreen-high-school-the-best-preschool-in-undri-pune",
    imageUrl: bg3,
  }
];

const Blogs = () => {
  return (
    <>
      <Helmet>
        <title>Explore Our Latest Blogs, Study Guides & Knowledge Activities | Hillgreen High School</title>
        <meta name="description" content="Discover a wealth of knowledge with our latest blogs, comprehensive study guides, and engaging activities from Hillgreen High School - the best preschool in Undri, Pune." />
        <meta name="keywords" content="Hillgreen High School, preschool in Undri, Pune preschool, educational blogs, study guides, learning activities" />
        <meta property="og:title" content="Explore Our Latest Blogs | Hillgreen High School" />
        <meta property="og:description" content="Discover a wealth of knowledge with our latest blogs, comprehensive study guides, and engaging activities from Hillgreen High School." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <div className={styles.blogList}>
        <h1 className={styles.heading}>Educational Insights & Resources</h1>
        <p className={styles.subheading}>Explore our collection of articles, guides, and resources designed to support your child's educational journey</p>
        <div className={styles.blogCards}>
          {blogs.map((blog) => (
            <div className={styles.blogCard} key={blog.id}>
              <div className={styles.imageContainer}>
                <img src={blog.imageUrl || "/placeholder.svg"} alt={blog.title} className={styles.blogImage} />
              </div>
              <div className={styles.contentContainer}>
                <h2 className={styles.blogTitle}>{blog.title}</h2>
                <p className={styles.blogDescription}>{blog.description}</p>
                <Link to={`/blog/${blog.url}`} className={styles.readMore}>
                  Read More →
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Blogs;