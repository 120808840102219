import React from 'react';
import styles from './Career.module.css';
import { Helmet } from 'react-helmet-async';


const Career = () => {
  return (
    <div className={styles.career}>
      <div className={styles.content}>
      <div className={styles.applyForm}>
          <h2>Apply for a Job</h2>
          <form>
            <div className={styles.formGroup}>
              <label htmlFor="name">Full Name</label>
              <input type="text" id="name" name="name" required />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="email">Email</label>
              <input type="email" id="email" name="email" required />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="phone">Phone Number</label>
              <input type="tel" id="phone" name="phone" required />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="resume">Upload Resume</label>
              <input type="file" id="resume" name="resume" required />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="message">Message</label>
              <textarea id="message" name="message" rows="4" required></textarea>
            </div>
            <button type="submit">Submit Application</button>
          </form>
        </div>
        {/* <div className={styles.vacancies}>
          <h2>Current Vacancies</h2>
          <ul>
            <li>
              <h3>Job Title 1</h3>
              <p>Location: City, Country</p>
              <p>Job Description: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum vestibulum.</p>
            </li>
            <li>
              <h3>Job Title 2</h3>
              <p>Location: City, Country</p>
              <p>Job Description: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum vestibulum.</p>
            </li>
            
          </ul>
        </div> */}
       
      </div>
    </div>
  );
};

export default Career;
