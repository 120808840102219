import React, { useState } from 'react'
import styles from './OnlineAdmission.module.css'
import { Helmet } from 'react-helmet-async';


// Import images or other files 


const OnlineAdmission = () => {

    const [formData, setFormData] = useState({
        academicYear: '',
        studentClass: '',
        firstName: '',
        lastName: '',
        fatherName: '',
        gender: '',
        motherName: '',
        whatsappNumber: '',
        email: '',
        phoneNumber: '',
        address: '',
        street: '',
        area: '',
        city: '',
        state: '',
        pinCode: '',
        transactionNumber: '',
        screenshot: null
    });

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData({
            ...formData,
            [name]: files ? files[0] : value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const formDataToSend = new FormData();
        Object.keys(formData).forEach((key) => {
            formDataToSend.append(key, formData[key]);
        });
    
        try {
            const response = await fetch("https://yeskondomedia.com/Mail/Hillgreenmail.php", {
                method: "POST",
                body: formDataToSend,
            });
    
            const result = await response.json();
            if (result.success) {
                alert("Form submitted successfully!");
                window.location.reload(); // Refresh the page after successful form submission
            } else {
                alert("Failed to submit form. Please try again.");
            }
        } catch (error) {
            console.error("Error submitting form:", error);
            alert("An error occurred. Please try again.");
        }
    };
    

    const onButtonClick = () => {
        const pdfUrl = "Admissionform.pdf";
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "Admissionform.pdf"; 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <>
            <Helmet>
                <title>Online Admission for Hillgreen High School | Apply Now</title>
                <meta name="description" content="Apply online for admission to Hillgreen High School. Download the admission form, fill it out, and submit with the required documents and fees." />
                <meta name="keywords" content="online admission, admission form, Hillgreen High School, school admission, apply online, admission process, student enrollment" />
                <meta property="og:title" content="Online Admission for Hillgreen High School | Apply Now" />
                <meta property="og:description" content="Apply online for admission to Hillgreen High School, download the form, and submit it with necessary documents and payment. Start your admission process today!" />
                <meta property="og:image" content="URL_TO_IMAGE_HERE" />
                <meta property="og:url" content="https://www.hillgreenhighschool.in/onlineadmission" />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content="Online Admission for Hillgreen High School | Apply Now" />
                <meta name="twitter:description" content="Download the admission form for Hillgreen High School, fill it out, and submit along with necessary documents. Apply for 2024-2025 session now!" />
                <meta name="twitter:image" content="URL_TO_IMAGE_HERE" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="robots" content="index, follow" />
            </Helmet>

            <div className={styles.onlineadmission}>
                <div className={styles.onlineadmission_head}>
                    <div className={styles.overlay}></div>
                    <div className={styles.onlineadmission_head_text}>
                        <h1>Online Admission</h1>
                    </div>
                </div>

                <div className={styles.onlineadmission_main}>
                    <div className={styles.onlineadmission_download_form}>
                        <h1>Online Admission Form </h1>

                        <p>Download the form. Fill it and submit it along with necessary documents and form fees.</p>
                        <a onClick={onButtonClick} download>
                            <button className={styles.download_form_button}>Download Application Form</button>
                        </a>
                    </div>
                </div>
            </div>


            {/* Bankers */}

            <div className={styles.bankers}>

                <div className={styles.banker_main}>
                    <div className={styles.banker_main_head}>
                        <h1>School Bank Account Details</h1>
                    </div>
                    <div className={styles.banker_main_flex}>
                        <div className={styles.banker_main_box}>
                            <i class="fa-solid fa-building-columns"></i>
                            <div className={styles.bankdetails}>
                                <li> <span className={styles.font_color}>A/C Name:</span> Hillgreen High School and Junior College</li>
                                <li> <span className={styles.font_color}>Bank Name:</span> Union Bank of India</li>
                                <li> <span className={styles.font_color}>A/C. Number:</span> 6169 0201 0000 392</li>
                                <li> <span className={styles.font_color}>IFSC Code:</span> UBIN0561690</li>
                                <li> <span className={styles.font_color}>Branch:</span> Undri</li>
                            </div>
                        </div>


                        <div className={styles.banker_main_box}>
                            <i class="fa-solid fa-building-columns"></i>
                            <div className={styles.bankdetails}>
                                <li> <span className={styles.font_color}>A/C Name:</span> Hillgreen High School and Junior College</li>
                                <li> <span className={styles.font_color}>Bank Name:</span> Federal Bank Limited</li>
                                <li> <span className={styles.font_color}>A/C. Number:</span> 18190100019677</li>
                                <li> <span className={styles.font_color}>IFSC Code:</span>FDRL0001819</li>
                                <li> <span className={styles.font_color}>Branch:</span> Bibwewadi</li>
                            </div>
                        </div>

                        <div className={styles.documents}>
                            <div className={styles.documents_inner}>
                                <h2>Documents Reqired</h2>
                                <ul className={styles.document_list}>
                                    <li>TRANSFER CERTIFICATE</li>
                                    <li>BIRTH CERTIFICATE</li>
                                    <li>TWO RECENT COLOR PHOTOS OF STUDENT</li>
                                    <li>TWO RECENT COLOR PHOTOS OF STUDENT</li>
                                    <li>RESULT OF LAST YEAR CLASS ATTENDED</li>
                                    <li>ADHAR CARD OF STUDENT</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.payment_confirmation_form}>
                    <div className={styles.main_form}>
                        <div className={styles.formContainer}>

                            <form onSubmit={handleSubmit} className={styles.form}>
                                <div className={styles.formGroup}>
                                    <label>Academic Year</label>
                                    <select name="academicYear" value={formData.academicYear} onChange={handleChange} required>
                                        <option value="">Select Year</option>
                                        <option value="2024-2025">2024-2025</option>

                                    </select>
                                </div>

                                <div className={styles.formGroup}>
                                    <label>Select Class</label>
                                    <select name="studentClass" value={formData.studentClass} onChange={handleChange} required>
                                        <option value="">Select Class</option>
                                        <option value="LKG">LKG</option>
                                        <option value="UKG">UKG</option>
                                        <option value="Nursery">Nursery</option>
                                        {Array.from({ length: 9 }, (_, i) => (
                                            <option key={i + 1} value={`Class ${i + 1}`}>{`Class ${i + 1}`}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className={styles.formGroup}>
                                    <label>Student First Name</label>
                                    <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} required />
                                </div>

                                <div className={styles.formGroup}>
                                    <label>Student Last Name</label>
                                    <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} required />
                                </div>

                                <div className={styles.formGroup}>
                                    <label>Father Name</label>
                                    <input type="text" name="fatherName" value={formData.fatherName} onChange={handleChange} required />
                                </div>

                                <div className={styles.formGroup}>
                                    <label>Select Gender</label>
                                    <select name="gender" value={formData.gender} onChange={handleChange} required>
                                        <option value="">Select Gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                </div>

                                <div className={styles.formGroup}>
                                    <label>Mother Name</label>
                                    <input type="text" name="motherName" value={formData.motherName} onChange={handleChange} required />
                                </div>

                                <div className={styles.formGroup}>
                                    <label>WhatsApp Number</label>
                                    <input type="text" name="whatsappNumber" value={formData.whatsappNumber} onChange={handleChange} required />
                                </div>

                                <div className={styles.formGroup}>
                                    <label>Email</label>
                                    <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                                </div>

                                <div className={styles.formGroup}>
                                    <label>Phone Number</label>
                                    <input type="text" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} required />
                                </div>

                                <div className={styles.formGroup}>
                                    <label>Flat/Building/Apartment</label>
                                    <input type="text" name="address" value={formData.address} onChange={handleChange} required />
                                </div>

                                <div className={styles.formGroup}>
                                    <label>Street/Road/Lane Number</label>
                                    <input type="text" name="street" value={formData.street} onChange={handleChange} required />
                                </div>

                                <div className={styles.formGroup}>
                                    <label>Area/Locality</label>
                                    <input type="text" name="area" value={formData.area} onChange={handleChange} required />
                                </div>

                                <div className={styles.formGroup}>
                                    <label>City</label>
                                    <input type="text" name="city" value={formData.city} onChange={handleChange} required />
                                </div>

                                <div className={styles.formGroup}>
                                    <label>State</label>
                                    <input type="text" name="state" value={formData.state} onChange={handleChange} required />
                                </div>

                                <div className={styles.formGroup}>
                                    <label>Pin Code</label>
                                    <input type="text" name="pinCode" value={formData.pinCode} onChange={handleChange} required />
                                </div>

                                <div className={styles.formGroup}>
                                    <label>Online Transaction Number</label>
                                    <input type="text" name="transactionNumber" value={formData.transactionNumber} onChange={handleChange} required />
                                </div>

                                <div className={styles.formGroup}>
                                    <label>Upload Transaction Screenshot</label>
                                    <input type="file" name="screenshot" onChange={handleChange} required />
                                </div>

                                <button type="submit" className={styles.submitButton}>Submit</button>
                            </form>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}

export default OnlineAdmission