import gallery1 from '../../Assets/Gallery/1.webp'
import gallery2 from '../../Assets/Gallery/2.webp'
import gallery3 from '../../Assets/Gallery/3.webp'
import gallery4 from '../../Assets/Gallery/4.webp'
import gallery5 from '../../Assets/Gallery/5.webp'
import gallery6 from '../../Assets/Gallery/6.webp'
import gallery7 from '../../Assets/Gallery/7.webp'
import gallery8 from '../../Assets/Gallery/8.webp'
import gallery9 from '../../Assets/Gallery/9.webp'
import gallery10 from '../../Assets/Gallery/10.webp'
import gallery11 from '../../Assets/Gallery/11.webp'
import gallery12 from '../../Assets/Gallery/12.webp'
import gallery13 from '../../Assets/Gallery/13.webp'
import gallery14 from '../../Assets/Gallery/14.webp'
// import gallery15 from '../../Assets/Gallery/15.webp'
import gallery16 from '../../Assets/Gallery/16.webp'
import gallery17 from '../../Assets/Gallery/17.webp'
import gallery18 from '../../Assets/Gallery/18.webp'
import gallery19 from '../../Assets/Gallery/19.webp'
// import gallery20 from '../../Assets/Gallery/20.webp'
import gallery21 from '../../Assets/Gallery/21.webp'
import gallery22 from '../../Assets/Gallery/22.webp'
import gallery23 from '../../Assets/Gallery/23.webp'
import gallery24 from '../../Assets/Gallery/24.webp'
import gallery25 from '../../Assets/Gallery/25.webp'
import gallery26 from '../../Assets/Gallery/26.webp'

import gallery27 from '../../Assets/Gallery/27.webp'
import gallery28 from '../../Assets/Gallery/28.webp'
import gallery29 from '../../Assets/Gallery/29.webp'
import gallery30 from '../../Assets/Gallery/30.webp'

import gallery31 from '../../Assets/Gallery/31.webp'
import gallery32 from '../../Assets/Gallery/32.webp'
import gallery33 from '../../Assets/Gallery/33.webp'
import gallery34 from '../../Assets/Gallery/34.webp'
import gallery35 from '../../Assets/Gallery/35.webp'


import gallery36 from '../../Assets/Gallery/36.webp'
import gallery37 from '../../Assets/Gallery/37.webp'
import gallery38 from '../../Assets/Gallery/38.webp'
import gallery39 from '../../Assets/Gallery/39.webp'
import gallery40 from '../../Assets/Gallery/40.webp'

import gallery41 from '../../Assets/Gallery/41.webp'
import gallery42 from '../../Assets/Gallery/42.webp'
import gallery43 from '../../Assets/Gallery/43.webp'
import gallery44 from '../../Assets/Gallery/44.webp'
import gallery45 from '../../Assets/Gallery/45.webp'


import gallery46 from '../../Assets/Gallery/46.webp'
import gallery47 from '../../Assets/Gallery/47.webp'
import gallery48 from '../../Assets/Gallery/48.webp'
import gallery49 from '../../Assets/Gallery/49.webp'
import gallery50 from '../../Assets/Gallery/45.webp'

import gallery51 from '../../Assets/Gallery/51.webp'
import gallery52 from '../../Assets/Gallery/52.webp'
import gallery53 from '../../Assets/Gallery/53.webp'
import gallery54 from '../../Assets/Gallery/54.webp'
import gallery55 from '../../Assets/Gallery/55.webp'

// New
import gallery56 from '../../Assets/Gallery/56.jpg'
import gallery57 from '../../Assets/Gallery/57.jpg'
import gallery58 from '../../Assets/Gallery/58.jpg'
import gallery59 from '../../Assets/Gallery/59.jpg'
import gallery60 from '../../Assets/Gallery/60.jpg'
import gallery61 from '../../Assets/Gallery/61.jpg'
import gallery62 from '../../Assets/Gallery/62.jpg'
import gallery63 from '../../Assets/Gallery/63.jpg'
import gallery64 from '../../Assets/Gallery/64.jpg'

import gallery65 from '../../Assets/Gallery/65.jpg'
import gallery66 from '../../Assets/Gallery/66.jpg'

import gallery67 from '../../Assets/Gallery/67.jpg'
import gallery68 from '../../Assets/Gallery/68.jpg'
import gallery69 from '../../Assets/Gallery/69.jpg'
import gallery70 from '../../Assets/Gallery/70.jpg'
import gallery71 from '../../Assets/Gallery/71.jpg'
import gallery72 from '../../Assets/Gallery/72.jpg'
import gallery73 from '../../Assets/Gallery/73.jpg'




const gallerydata = [
    { image: gallery1, category: 'School Campus' },
    { image: gallery2 , category: 'School Campus'},
    { image: gallery3 , category: 'School Campus'},
    { image: gallery4, category: 'School Campus' },
    { image: gallery5, category: 'Celebrations' },
    { image: gallery6, category: 'School Campus' },
    { image: gallery7, category: 'School Campus' },
    { image: gallery8, category: 'Facilities' },
    { image: gallery9, category: 'School Campus' },
    { image: gallery10,  category: 'Facilities' },
    { image: gallery11 },
    { image: gallery12,  category: 'Facilities' },
    { image: gallery13 },
    { image: gallery14 },
    // { image: gallery15 },
    { image: gallery16 },
    { image: gallery17 },
    { image: gallery18 },
    { image: gallery19 },
    // { image: gallery20 },
    { image: gallery21 },
    { image: gallery22 },
    { image: gallery23 },
    { image: gallery24 },
    { image: gallery25,category: 'Facilities'},
    { image: gallery26,category: 'Facilities'},

    { image: gallery27,category: 'Sports & Competitions'},
    { image: gallery28,category: 'Sports & Competitions'},
    { image: gallery29,category: 'Sports & Competitions'},
    { image: gallery30,category: 'Sports & Competitions'},

    { image: gallery31,category: 'Sports & Competitions'},
    { image: gallery32,category: 'Sports & Competitions'},
    { image: gallery33,category: 'Sports & Competitions'},
    { image: gallery34,category: 'Sports & Competitions'},
    { image: gallery35,category: 'Sports & Competitions'},

    { image: gallery36,category: 'Sports & Competitions'},
    { image: gallery37,category: 'Sports & Competitions'},
    { image: gallery38,category: 'Sports & Competitions'},
    { image: gallery39,category: 'Sports & Competitions'},
    { image: gallery40,category: 'Sports & Competitions'},

    { image: gallery41,category: 'Activities'},
    { image: gallery42,category: 'Activities'},
    { image: gallery43,category: 'Activities'},
    { image: gallery44,category: 'Activities'},
    { image: gallery45,category: 'Activities'},

    { image: gallery46,category: 'Facilities'},
    { image: gallery47,category: 'Facilities'},
    { image: gallery48,category: 'Facilities'},
    { image: gallery49,category: 'Facilities'},
    { image: gallery50,category: 'Facilities'},


    { image: gallery51,category: 'Social Connection'},
    { image: gallery52,category: 'Social Connection'},
    { image: gallery53,category: 'Social Connection'},
    { image: gallery54,category: 'Social Connection'},
    { image: gallery55,category: 'Social Connection'},

    // New
    { image: gallery56,category: 'Republic Day'},
    { image: gallery57,category: 'Republic Day'},
    { image: gallery58,category: 'Republic Day'},
    { image: gallery59,category: 'Republic Day'},
    { image: gallery60,category: 'Republic Day'},
    { image: gallery61,category: 'Republic Day'},
    { image: gallery62,category: 'Republic Day'},
    { image: gallery63,category: 'Republic Day'},
    { image: gallery64,category: 'Republic Day'},


    { image: gallery65,category: 'Sports & Competitions'},
    { image: gallery66,category: 'Sports & Competitions'},


    { image: gallery67,category: 'Celebrations'},
    { image: gallery68,category: 'Celebrations'},
    { image: gallery69,category: 'Celebrations'},
    { image: gallery70,category: 'Celebrations'},
    { image: gallery71,category: 'Celebrations'},
    { image: gallery72,category: 'Celebrations'},
    { image: gallery73,category: 'Celebrations'},




];

export default gallerydata;
