import React, { useEffect, useState } from 'react'
import styles from './Outdoor.module.css'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import outdoor from '../../../Assets/Activities/outdoor.webp'
import out1 from '../../../Assets/Outdoor/out1.webp'
import out2 from '../../../Assets/Outdoor/out2.webp'
import out3 from '../../../Assets/Outdoor/out3.webp'
import out4 from '../../../Assets/Outdoor/out4.webp'
import out5 from '../../../Assets/Outdoor/out5.webp'
import out6 from '../../../Assets/Outdoor/out6.webp'
import out7 from '../../../Assets/Outdoor/out7.webp'
import out8 from '../../../Assets/Outdoor/out8.webp'
import out9 from '../../../Assets/Outdoor/out9.webp'
import out10 from '../../../Assets/Outdoor/out10.webp'
import out11 from '../../../Assets/Outdoor/out11.webp'
import out12 from '../../../Assets/Outdoor/out12.webp'
import out13 from '../../../Assets/Outdoor/out13.webp'
import out14 from '../../../Assets/Outdoor/out14.webp'
import { Helmet } from 'react-helmet-async';

const Outdoor = () => {
    const [lightboxIndex, setLightboxIndex] = useState(0);
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxImagesLoaded, setLightboxImagesLoaded] = useState(false);

    const openLightbox = (index) => {
        setLightboxIndex(index);
        setLightboxOpen(true);
    };

    const closeLightbox = () => {
        setLightboxOpen(false);
    };

    const handleLightboxImagesLoad = () => {
        setLightboxImagesLoaded(true);
    };

    const outImages = [
        out1, out2, out3, out4, out5, out6, out7, out8, out9, out10,
        out11, out12, out13, out14
    ];

    return (
        <>
        <Helmet>
            <title> Outdoor Activities for Student Development at Hillgreen High School</title>
            <meta name="description" content="At Hillgreen High School, outdoor activities are key to student development. Join us for sports like football, basketball, and more" />
        </Helmet>
            <div className={styles.outdoor}>
                <div className={styles.outdoor_head}>
                    <div className={styles.overlay}></div>
                    <div className={styles.outdoor_head_text}>
                        <h1>Outdoor</h1>
                    </div>
                </div>

                <div className={styles.outdoor_main}>
                    
                    <div className={styles.outdoor_main_content}>
                    
                        <div className={styles.outdoor_main_head}>
                            <h1>Outdoor Activities</h1>
                            <p>At Hillgreen High School, we believe in the importance of outdoor activities for the holistic development of our students. Our expansive playgrounds provide ample space for a variety of sports and physical activities. Students can participate in team sports such as football, cricket, and basketball, which promote teamwork and physical fitness.</p>
                        </div>

                        <div className={styles.outdoor_main_text}>
                            <div className={styles.outdoor_para}>
                                <p>The school lays a lot of emphasis on co-curricular activities. Ample opportunities exist on the campus to for these activities. There are spacious playgrounds for all outdoor games and sports such as, Football, Basketball, Athletics, Volleyball, Kho Kho, etc.</p>
                            </div>
                            <div className={styles.outdoor_img}>
                                <img src={outdoor} alt='Hillgreen Outdoor' />
                            </div>
                        </div>
                    </div>

                    <div className={styles.outdoor_images}>
                        {outImages.map((image, index) => (
                            <img
                                key={index}
                                src={image}
                                className={lightboxOpen || lightboxImagesLoaded ? styles.visible : styles.hidden}
                                onClick={() => openLightbox(index)}
                                onLoad={handleLightboxImagesLoad}
                            />
                        ))}
                        {lightboxOpen && (
                            <Lightbox
                                mainSrc={outImages[lightboxIndex]}
                                nextSrc={outImages[(lightboxIndex + 1) % outImages.length]}
                                prevSrc={outImages[(lightboxIndex + outImages.length - 1) % outImages.length]}
                                onCloseRequest={closeLightbox}
                                onMovePrevRequest={() => setLightboxIndex((lightboxIndex + outImages.length - 1) % outImages.length)}
                                onMoveNextRequest={() => setLightboxIndex((lightboxIndex + 1) % outImages.length)}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Outdoor
