import React from 'react';
import styles from './VisionMission.module.css';
import square from '../../../Assets/list.png';
import { Helmet } from 'react-helmet-async';

const VisionMission = () => {
    return (
        <>
            <Helmet>
                <title>Vision & Mission - Hillgreen High School | Premier School in Pune</title>
                <meta name="description" content="Explore the Vision and Mission of Hillgreen High School, a leading educational institution in Pune. Learn about our commitment to holistic education and student development." />
                <meta name="keywords" content="Hillgreen High School, vision, mission, educational philosophy, student development, Pune school" />

                {/* Open Graph Metadata */}
                <meta property="og:title" content="Vision & Mission - Hillgreen High School | Premier School in Pune" />
                <meta property="og:description" content="At Hillgreen High School, we are committed to nurturing strength, wisdom, and character in our students. Discover our vision and mission for shaping the future." />
                <meta property="og:image" content="URL_TO_IMAGE_HERE" /> {/* Replace with an image representing the school's vision or mission */}
                <meta property="og:url" content="https://www.hillgreenhighschool.in/vision-mission" />
                <meta property="og:type" content="website" />

                {/* Twitter Card Metadata */}
                <meta name="twitter:title" content="Vision & Mission - Hillgreen High School | Premier School in Pune" />
                <meta name="twitter:description" content="Discover Hillgreen High School's Vision and Mission, focusing on the all-round development of students and progressive education techniques." />
                <meta name="twitter:image" content="URL_TO_IMAGE_HERE" /> {/* Replace with an image URL for Twitter */}
                <meta name="twitter:card" content="summary_large_image" />

                {/* Other meta tags for SEO */}
                <meta name="robots" content="index, follow" /> {/* Tells search engines to index this page */}
            </Helmet>
            <div className={styles.visionmission}>
                <div className={styles.visionmission_head}>
                    <div className={styles.overlay}></div>
                    <div className={styles.visionmission_head_text}>
                        <h1>Vision & Mission</h1>
                    </div>
                </div>

                {/* Vision and Mission Sections */}
                <div className={styles.vision_flexbox}>
                    <div className={styles.vision_box}>
                        <div>
                            <h2>OUR VISION</h2>
                            <div className={styles.main_content}>
                                <p>We the Principal, Teachers & Staff of Hillgreen High School and Junior College</p>
                                <p>Pledge to offer parents the best education and development for their children by nurturing the values Strength, Wisdom, and Character, which will lead them to success and satisfaction in life.</p>
                            </div>
                        </div>
                    </div>

                    <div className={styles.vision_box}>
                        <div>
                            <h2>OUR MISSION</h2>
                            <p>As the Potter moulds the clay, we will:</p>
                            <ul className={styles.points}>
                                <li className={styles.list_styles}>
                                    <img src={square} alt="Bullet point" />
                                    <span>Ensure the overall development of each student</span>
                                </li>
                                <li className={styles.list_styles}>
                                    <img src={square} alt="Bullet point" />
                                    <span>Adopt progressive techniques in education.</span>
                                </li>
                                <li className={styles.list_styles}>
                                    <img src={square} alt="Bullet point" />
                                    <span>Guide students in achieving outstanding results.</span>
                                </li>
                                <li className={styles.list_styles}>
                                    <img src={square} alt="Bullet point" />
                                    <span>Develop skills, endurance, and a positive attitude to face challenges.</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default VisionMission;
