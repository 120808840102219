import React from 'react'
import styles from './Curriculum.module.css'

import curriculum_stud from '../../../Assets/Learning/curriculum_stud.webp'
import curriculum_teach from '../../../Assets/Gallery/5.webp'
import { Helmet } from 'react-helmet-async';


const Curriculum = () => {
    return (
        <>
            <Helmet>
                <title>Curriculum at Hillgreen High School | Learning Beyond Academics</title>
                <meta name="description" content="Explore the curriculum at Hillgreen High School, including CBSE-based education and innovative teaching methods that make learning fun and engaging for students." />
                <meta name="keywords" content="Hillgreen High School, curriculum, CBSE, innovative teaching, student learning, education, teachers, hands-on learning" />

                {/* Open Graph Meta Tags */}
                <meta property="og:title" content="Curriculum at Hillgreen High School | Learning Beyond Academics" />
                <meta property="og:description" content="At Hillgreen High School, we offer a comprehensive CBSE curriculum with innovative teaching methods that foster both academic and personal growth." />
                <meta property="og:image" content="URL_TO_IMAGE_HERE" />
                <meta property="og:url" content="https://www.hillgreenhighschool.in/curriculum" />
                <meta property="og:type" content="website" />

                {/* Twitter Card Meta Tags */}
                <meta name="twitter:title" content="Curriculum at Hillgreen High School | Learning Beyond Academics" />
                <meta name="twitter:description" content="Explore the engaging and innovative curriculum at Hillgreen High School that goes beyond academics to nurture well-rounded students." />
                <meta name="twitter:image" content="URL_TO_IMAGE_HERE" />
                <meta name="twitter:card" content="summary_large_image" />
                {/* Robots Meta Tag */}
                <meta name="robots" content="index, follow" />
            </Helmet>


            <div className={styles.curriculum}>
                <div className={styles.curriculum_head}>
                    <div className={styles.overlay}></div>
                    <div className={styles.curriculum_head_text}>
                        <h1>Curriculum</h1>
                    </div>
                </div>

                <div className={styles.curriculum_main}>
                    <div className={styles.curriculum_box}>
                        <div className={styles.curriculum_box_head}>
                            <img src={curriculum_stud} alt='Hillgreen' />
                        </div>
                        <div className={styles.curriculum_box_content}>
                            <h2>Our Curriculum</h2>
                            <p>The Hillgreen High School & Junior College is an english medium institution. The School follows CBSE Board curriculum. Going beyond the curriculum, we always encourage our children to expand their horizons – to include other intellect and personality developing activities which makes learning fun.</p>
                        </div>

                    </div>



                    <div className={`${styles.curriculum_box} ${styles.curriculum_box_reverse} `}>
                        <div className={styles.curriculum_box_content}>
                            <h2>Our Teachers</h2>
                            <p>All subjects are taught by skilled, qualified, experienced and dedicated teachers - all experts in imparting education to children. These teachers prepare the children not only to excel in their academics, but also in the real world outside. Tolerant, understanding and open minded, they leverage modern and innovative teaching methods to extract outstanding results. What's more, the sprinkling of teachers from NGO backgrounds ensures that each child has a sensitive and compassionate sounding board to round off the learning experience.</p>
                            <br />
                            <p>Sharing the conviction of the school, that learning should be a fun-filled and joyous experience, these teachers are also friends and guides to the children. They are the exhilarating wind beneath the fledgling wings of the children that helps them to soar high.</p>
                        </div>
                        <div className={styles.curriculum_box_head}>
                            <img src={curriculum_teach} alt='Hillgreen' />
                        </div>
                    </div>

                    <div className={styles.curriculum_extra}>
                        <div className={styles.overlay2}></div>
                        <div className={styles.curriculum_extra_text}>
                            <h1>Not just education, but learning</h1>
                            <p>At The Hillgreen School, we believe that although education may not necessarily be fun, learning can be. Therefore, we have made the learning process an absorbing, interesting and enjoyable experience for the child. We employ innovative teaching methods to get greater student involvement. Like getting them to experience hands on all that they have learnt in their classrooms. Because only by making learning an actual first hand ‘real’ experience, can the entire process become more interesting and fun.</p>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}

export default Curriculum