import React, { useRef, useEffect } from 'react';
import styles from './SchoolHistory.module.css';
import history from '../../../Assets/About/History/Vid.mp4';
import { Helmet } from 'react-helmet-async';

const SchoolHistory = () => {
  const videoRef = useRef(null);
  const isPlaying = useRef(false);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const handlePlayVideo = (entries) => {
      entries.forEach(entry => {
        const video = videoRef.current;
        if (!video) return;

        if (entry.isIntersecting && !isPlaying.current) {
          video.play().catch((error) => {
            console.error('Error attempting to play video:', error);
          });
          isPlaying.current = true;
        } else if (!entry.isIntersecting && isPlaying.current) {
          video.pause();
          isPlaying.current = false;
        }
      });
    };

    const observer = new IntersectionObserver(handlePlayVideo, options);

    const currentVideo = videoRef.current; // Copy ref to a local variable

    if (currentVideo) {
      observer.observe(currentVideo);
    }

    return () => {
      if (currentVideo) {
        observer.unobserve(currentVideo);
      }
    };
  }, []);

  return (
    <>
      {/* SEO */}
      <Helmet>
        <title>Hillgreen High School - Our History | Premier School in Pune</title>
        <meta name="description" content="Explore the rich history of Hillgreen High School, a leading educational institution in Pune. Learn about our journey and legacy in shaping young minds." />
        <meta name="keywords" content="Hillgreen High School, school history, Pune school, educational legacy, history of Hillgreen, best school in Pune" />
        <meta property="og:title" content="Hillgreen High School - Our History | Premier School in Pune" />
        <meta property="og:description" content="Discover the inspiring history of Hillgreen High School in Pune. Learn about our founding, growth, and commitment to excellence in education." />
        <meta property="og:image" content="URL_TO_IMAGE_HERE" />
        <meta property="og:url" content="https://www.hillgreenhighschool.in/schoolhistory" />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content="Hillgreen High School - Our History | Premier School in Pune" />
        <meta name="twitter:description" content="Learn about the rich history of Hillgreen High School, Pune. A premier educational institution with a legacy of excellence." />
        <meta name="twitter:image" content="URL_TO_IMAGE_HERE" /> 
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="robots" content="index, follow" /> 
      </Helmet>

      <div className={styles.schoolhistory}>
        <div className={styles.schoolhistory_head}>
          <div className={styles.overlay}></div>
          <div className={styles.schoolhistory_head_text}>
            <h2>Know Us Better</h2>
            <h1>About Us</h1>
          </div>
        </div>
        <div className={styles.schoolhistory_maincontent}>
          <div className={styles.schoolhistory_section}>
            <div className={styles.schoolhistory_section_title}>
              <h2>Hillgreen's History</h2>
            </div>

            <div className={styles.scrollenvelope}>
              <video ref={videoRef} muted title="Hillgreen High School History Video">
                <source src={history} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SchoolHistory;
